// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-company-culture-js": () => import("./../../../src/pages/careers/company-culture.js" /* webpackChunkName: "component---src-pages-careers-company-culture-js" */),
  "component---src-pages-careers-core-values-js": () => import("./../../../src/pages/careers/core-values.js" /* webpackChunkName: "component---src-pages-careers-core-values-js" */),
  "component---src-pages-careers-open-positions-js": () => import("./../../../src/pages/careers/open-positions.js" /* webpackChunkName: "component---src-pages-careers-open-positions-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-fundamentals-draft-innovation-js": () => import("./../../../src/pages/fundamentals/draft-innovation.js" /* webpackChunkName: "component---src-pages-fundamentals-draft-innovation-js" */),
  "component---src-pages-fundamentals-family-farming-js": () => import("./../../../src/pages/fundamentals/family-farming.js" /* webpackChunkName: "component---src-pages-fundamentals-family-farming-js" */),
  "component---src-pages-fundamentals-farm-history-js": () => import("./../../../src/pages/fundamentals/farm-history.js" /* webpackChunkName: "component---src-pages-fundamentals-farm-history-js" */),
  "component---src-pages-fundamentals-farm-team-js": () => import("./../../../src/pages/fundamentals/farm-team.js" /* webpackChunkName: "component---src-pages-fundamentals-farm-team-js" */),
  "component---src-pages-fundamentals-sustainability-js": () => import("./../../../src/pages/fundamentals/sustainability.js" /* webpackChunkName: "component---src-pages-fundamentals-sustainability-js" */),
  "component---src-pages-fundamentals-what-we-grow-grains-js": () => import("./../../../src/pages/fundamentals/what-we-grow/grains.js" /* webpackChunkName: "component---src-pages-fundamentals-what-we-grow-grains-js" */),
  "component---src-pages-fundamentals-what-we-grow-tomatoes-js": () => import("./../../../src/pages/fundamentals/what-we-grow/tomatoes.js" /* webpackChunkName: "component---src-pages-fundamentals-what-we-grow-tomatoes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-and-community-community-leadership-js": () => import("./../../../src/pages/industry-and-community/community-leadership.js" /* webpackChunkName: "component---src-pages-industry-and-community-community-leadership-js" */),
  "component---src-pages-industry-and-community-industry-leadership-js": () => import("./../../../src/pages/industry-and-community/industry-leadership.js" /* webpackChunkName: "component---src-pages-industry-and-community-industry-leadership-js" */),
  "component---src-pages-landowners-js": () => import("./../../../src/pages/landowners.js" /* webpackChunkName: "component---src-pages-landowners-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-bios-js": () => import("./../../../src/templates/bios.js" /* webpackChunkName: "component---src-templates-bios-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */)
}

